var FirstNewsItemViewModel = function (pageId, cultureCode) {//, maxItemsPerPage) {
    var self = this;

    //static
    self.pageId = pageId;
    self.cultureCode = cultureCode;

    //observables   
    self.firstNewsItem = ko.observableArray([]);

   //functions   
    self.GetFirstNewsItem = function () {

            $.ajax({
                url: "/umbraco/api/Ajax/GetFirstNewsItem/",
                type: "GET",
                data: { contentId: self.pageId, cultureCode: self.cultureCode },
                dataType: "json",
                success: function (returnData) {
                    //console.log(returnData.Data.NewsItemObject);
                    if (returnData.Data.NewsItemObject) {
                        var newsObj = ko.mapping.fromJSON(returnData.Data.NewsItemObject);
                        self.firstNewsItem(newsObj);
                    }                   
                },
                error: function (e) {
                    console.log(e.statusText + ": " + e.responseText); //todo: better error handling
                },
                complete: function () {

                }
            });

    }

    self.GetFirstNewsItem();

    return self;
};