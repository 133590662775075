var NewsListViewModel = function (pageId, cultureCode) {//, maxItemsPerPage) {
    var self = this;
    
    //static
    self.pageId = pageId;
    self.cultureCode = cultureCode;
    self.maxItemsPerPage = 6;

    //observables
    self.disabled = ko.observable(false);
    self.startNewsList = ko.observableArray([]);
    self.categoryList = ko.observableArray([]);
    self.firstNewsItem = ko.observableArray([]);
    self.pageNr = ko.observable(1);
    self.pageTotalNr = ko.observable(1);
    self.totalNr = ko.observable("");
    //self.allCategories = ko.observable("all");
    self.selectedCategory = ko.observable("All");
    self.paginationVisible = ko.observable(false);
    self.PaginationButtonClick = function (number, btn) {
        $(".pagination-btns").parent().removeClass("active");
        btn.parent().addClass("active");
        self.pageNr(number);
        //self.GetNews("", 6);
        var category = self.selectedCategory();
        self.GetFilteredNews("", category, 6);
    };
    self.NextButtonClick = function () {
        if (self.disabled() === false) {
            $(".pagination-btns").parent().removeClass("active");
            var btn = $("#pagination-btn-" + self.pageNr());
            //console.log(btn.parent());
            btn.parent().addClass("active");
            self.pageNr(parseInt(self.pageNr()) + 1);
            //self.GetNews("", 6);
            var category = self.selectedCategory();
            self.GetFilteredNews("", category, 6);
        }
        
    };
    self.PreviousButtonClick = function () {
        var prvPage = parseInt(self.pageNr()) - 1;
        
        if (prvPage > 0) {
            
            self.pageNr(prvPage);
            //self.GetNews("", 6);
            var category = self.selectedCategory();
            self.GetFilteredNews("", category, 6);
            prvPage = prvPage - 1;
            $(".pagination-btns").parent().removeClass("active");
            var btn = $("#pagination-btn-" + prvPage);
            //console.log(prvPage);
            btn.parent().addClass("active");
    
        }

    };
    self.FilterButtonClick = function (btn) {
       $("li .categories").removeClass("active");        
        var category = btn.parent().data("filter");
        self.selectedCategory(category);
        self.pageNr(1);
        self.GetFilteredNews("", category, 6);
        //btn.parent().addClass("active");
        console.log(btn.parent());

    };
    self.GetNews = function (data, count) {
       
            $.ajax({
                url: "/umbraco/api/Ajax/GetNews/",
                type: "GET",
                data: { contentId: self.pageId, cultureCode: self.cultureCode, pageNr: self.pageNr(), maxItemsPerPage: count }, //self.maxItemsPerPage },
                dataType: "json",
                success: function (returnData) {
                    if (returnData.Data.NewsList) {
                        var currentNewsList = ko.mapping.fromJSON(returnData.Data.NewsList);
                        self.startNewsList(currentNewsList());
                                             
                    }
                    if (returnData.Data.CategoryList) {
                        var categoryNewsList = ko.mapping.fromJSON(returnData.Data.CategoryList);
                        self.categoryList(categoryNewsList());
                        //console.log(categoryNewsList());
                    }                   
                    if (returnData.Data.TotalNumber) {
                        var newsCount = returnData.Data.TotalNumber;
                        if (newsCount > 7) {
                            self.paginationVisible(true);
                            var number = parseInt((newsCount - 1) / 6);
                            if (newsCount%6 != 0) {
                                number += 1;
                            }
                            self.pageTotalNr(number);
                            
                        }
                        self.totalNr("(" + newsCount + ")");

                    }
                    if (returnData.Data.End === true) {
                        self.disabled(true);
                    } else {
                        self.disabled(false);
                    }
                   

                },
                error: function (e) {
                    console.log(e.statusText + ": " + e.responseText); //todo: better error handling
                },
                complete: function () {
                   $('.news-item.secondary-news').matchHeight({
                      byRow: true,
                      property: 'height',
                      target: null,
                      remove: false
                   });
                 
                }
            });

    }
    self.GetFilteredNews = function (data, category, count) {
        //console.log(category);
        $.ajax({
            url: "/umbraco/api/Ajax/GetFilteredNews/",
            type: "GET",
            data: { contentId: self.pageId, cultureCode: self.cultureCode, categoryName: category, pageNr: self.pageNr(), maxItemsPerPage: count }, //self.maxItemsPerPage },
            dataType: "json",
            success: function (returnData) {
               //console.log(returnData);
                if (returnData.Data.NewsList) {
                    var currentNewsList = ko.mapping.fromJSON(returnData.Data.NewsList);
                    self.startNewsList(currentNewsList());
                    if (returnData.Data.FirstNewsItem) {
                       var newsObj = ko.mapping.fromJSON(returnData.Data.FirstNewsItem);
                       self.firstNewsItem(newsObj);
                       
                    }
                    if (returnData.Data.CategoryList) {
                        var categoryNewsList = ko.mapping.fromJSON(returnData.Data.CategoryList);
                        self.categoryList(categoryNewsList());
                        //console.log(categoryNewsList());
                    }
                    if (returnData.Data.TotalNumber) {
                        var catNewsCount = currentNewsList().length;
                        var newsCount = returnData.Data.TotalNumber;
                        //console.log(catNewsCount);
                        if (category == "All") {
                            catNewsCount = newsCount;
                        }
                        if (catNewsCount > 6) {
                            self.paginationVisible(true);
                            var number = parseInt((catNewsCount - 1) / 6);
                            if (catNewsCount % 6 != 0) {
                                number += 1;
                            }
                            self.pageTotalNr(number);

                        } else {
                            self.paginationVisible(false);

                        }
                        self.totalNr("(" + newsCount + ")");

                    }
                    //self.selectedCategory(category);
                }
                
                
                if (returnData.Data.End === true) {
                    self.disabled(true);
                } else {
                    self.disabled(false);
                }


            },
            error: function (e) {
                console.log(e.statusText + ": " + e.responseText); //todo: better error handling
            },
            complete: function () {
               $('.news-item.secondary-news').matchHeight({
                  byRow: true,
                  property: 'height',
                  target: null,
                  remove: false
               });
               
            }
        });

    }
    self.GetFirstNewsItem = function () {

        $.ajax({
            url: "/umbraco/api/Ajax/GetFirstNewsItem/",
            type: "GET",
            data: { contentId: self.pageId, cultureCode: self.cultureCode },
            dataType: "json",
            success: function (returnData) {
                //console.log(returnData.Data.NewsItemObject);
                if (returnData.Data.NewsItemObject) {
                    var newsObj = ko.mapping.fromJSON(returnData.Data.NewsItemObject);
                    self.firstNewsItem(newsObj);
                }
            },
            error: function (e) {
                console.log(e.statusText + ": " + e.responseText); //todo: better error handling
            },
            complete: function () {

            }
        });

    }
    //self.GetNewsTotalNumber = function () {

    //    $.ajax({
    //        url: "/umbraco/api/Ajax/GetTotalNewsNumber/",
    //        type: "GET",
    //        data: { contentId: self.pageId, cultureCode: self.cultureCode },
    //        dataType: "json",
    //        success: function (returnData) {
    //            //console.log(returnData.Data.TotalNumber);
    //            if (returnData.Data.TotalNumber) {
    //                var newsCount = returnData.Data.TotalNumber;
    //                if (newsCount > 6) {
    //                    self.paginationVisible(true);
    //                }
    //            }
    //        },
    //        error: function (e) {
    //            console.log(e.statusText + ": " + e.responseText); //todo: better error handling
    //        },
    //        complete: function () {

    //        }
    //    });

    //}
    self.GetFirstNewsItem();
    //self.GetNews("", 6);
    self.GetFilteredNews("", "All", 6);
    //self.GetNewsTotalNumber();
    return self;
};