//GetProjects(int contentId, string cultureCode, string categoryName)
var ProjectListViewModel = function (pageId, cultureCode) {
    var self = this;

    //static
    self.pageId = pageId;
    self.cultureCode = cultureCode;


    //observables
   
    self.projectList = ko.observableArray([]);
    self.categoryList = ko.observableArray([]);
    
    
    //self.allCategories = ko.observable("all");

    
    self.FilterButtonClick = function (btn) {
        //$(".categories").removeClass("active");
        //btn.addClass("active");
        var category = btn.data("filter");
        //console.log(btn.parent());
        self.GetProjects("", category);
    };
    self.GetProjects = function (data, category) {

        $.ajax({
            url: "/umbraco/api/Ajax/GetProjects/",
            type: "GET",
            data: { contentId: self.pageId, cultureCode: self.cultureCode, categoryName: category },
            dataType: "json",
            success: function (returnData) {
                if (returnData.Data.ProjectList) {
                    var currentProjectList = ko.mapping.fromJSON(returnData.Data.ProjectList);
                    self.projectList(currentProjectList());

                }
                if (returnData.Data.CategoryList) {
                    var categoryProjectList = ko.mapping.fromJSON(returnData.Data.CategoryList);
                    self.categoryList(categoryProjectList());
                    //console.log(categoryNewsList());
                }            

            },
            error: function (e) {
                console.log(e.statusText + ": " + e.responseText); //todo: better error handling
            },
            complete: function () {
               $('.donation-item .content').matchHeight({
                  byRow: true,
                  property: 'height',
                  target: null,
                  remove: false
               });
            }
        });

    }
   
    self.GetProjects("", "All");
    
    return self;
};